<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="q-gutter-sm">
        <q-select
          outlined
          v-model="selJenjang"
          :options="$LIST_PENGASUHAN"
          @input="getListMusyrif"
          label="jenjang"
        ></q-select>
        <q-input outlined label="Cari Musyrif" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-scroll-area style="height:calc(100vh - 238px);">
        <q-list bordered separator class="bg-indigo-1">
          <q-item
            clickable
            v-ripple
            v-for="(val, i) in filMusyrif"
            :key="i"
            @click="showAnggotaHalaqoh(val)"
          >
            <q-item-section>
              <q-item-label>{{ val.nama }}</q-item-label>
              <q-item-label caption>{{ val.jenjang }}</q-item-label>
              <q-item-label caption
                >Jumlah Santri : {{ val.jml_siswa }}</q-item-label
              >
            </q-item-section>
            <q-item-section side top>
              <q-btn flat icon="info" size="sm"></q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-scroll-area>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selJenjang: "PENGASUHAN PUTRA",
      listMusyrif: [],
      searchTerm: "",
    };
  },
  computed: {
    filMusyrif() {
      if (this.searchTerm == "") return this.listMusyrif;
      else {
        return this.listMusyrif.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  async mounted() {
    await this.getListMusyrif();
  },
  methods: {
    async getListMusyrif() {
      let resp = await this.$http.get(
        `/pengaturan/musyrif/list/${this.selJenjang}`
      );
      this.listMusyrif = resp.data;
    },
    showAnggotaHalaqoh(val) {
      this.$router.push(`/pengaturananggotamusyrif/${val.id}`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
